import { newEmptyWarehouse, type Warehouse } from '../domain/warehouse'
import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from '../../store/store'
import { type Log } from '../domain/log'

export interface WarehouseState {
  warehouse: Warehouse
  loading: boolean
}

const initialState: WarehouseState = {
  warehouse: newEmptyWarehouse(),
  loading: false
}

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    logReceived: (state, action) => {
      state.warehouse.liveLogs.push(action.payload)
    },
    monitorWarehouse: (state, action) => {
      state.loading = true
    },
    retrieveWarehouse: (state, action) => {
      state.loading = true
    },
    warehouseMonitored: (state, action) => {
      state.warehouse = action.payload
      state.loading = false
    },
    stopMonitorWarehouse: (state, action) => {
      state.warehouse.liveLogs = []
    }
  }
})

export const selectWarehouseState = (state: RootState): WarehouseState => state.warehouse

export const selectLiveLogs = (state: RootState): Log[] => state.warehouse.warehouse.liveLogs

export const {
  warehouseMonitored,
  retrieveWarehouse,
  logReceived,
  monitorWarehouse,
  stopMonitorWarehouse
} = warehouseSlice.actions

export default warehouseSlice.reducer
