import { InMemoryPonfigueAPI } from './gateway/ponfigue/inmem/ponfigue'
import { newWarehouse } from '../app/domain/warehouse'
import { HTTPPonfigueAPI } from './gateway/ponfigue/http/ponfigue'

const inMemoryDependencies = {
  ponfigue: new InMemoryPonfigueAPI(
    [
      newWarehouse('Madrid y su region'),
      newWarehouse('Paris et région parisienne')
    ]
  )
}

const externalDependencies = {
  ponfigue: new HTTPPonfigueAPI('wss://4745yq0y88.execute-api.eu-west-3.amazonaws.com/production', [
    newWarehouse('Madrid y su region'),
    newWarehouse('Paris et région parisienne')
  ])
}

export default process.env.REACT_APP_IN_MEMORY === 'true' ? inMemoryDependencies : externalDependencies
