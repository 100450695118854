import { startListening } from '../../store/middleware'
import { logReceived, monitorWarehouse, warehouseMonitored } from '../state/warehouse'

const listenMonitorWarehouse = (): any => startListening({
  actionCreator: monitorWarehouse,
  effect: async (action, listenerApi) => {
    const deps = listenerApi.extra.deps

    try {
      const warehouse = await deps.ponfigue.findWarehouse(action.payload)

      await deps.ponfigue.monitorLogs(
        warehouse.name,
        (log) => listenerApi.dispatch(logReceived(log))
      )

      listenerApi.dispatch(warehouseMonitored(warehouse))
    } catch (e) {
      console.log(e)
    }
  }
})

export default listenMonitorWarehouse
