import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { store } from './store/store'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import Dashboards from './adapter/page/dashboards/Dashboards'
import { retrieveWarehouses } from './app/state/warehouseList'
import Dashboard from './adapter/page/dashboard/Dashboard'
import { monitorWarehouse } from './app/state/warehouse'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)
const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Navigate to={'/dashboards'}/>
    },
    {
      path: '/dashboards',
      loader: () => store.dispatch(retrieveWarehouses()),
      children: [
        {
          index: true,
          element: <Dashboards/>
        },
        {
          path: ':warehouse',
          element: <Dashboard/>,
          loader: ({ params }) => store.dispatch(monitorWarehouse(params.warehouse))
        }
      ]
    }
  ]
)

root.render(
  <Provider store={store}>
    <RouterProvider router={router}/>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to logs results (for example: reportWebVitals(console.logs))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
