import { type Log } from './log'

export interface Warehouse {
  name: string
  liveLogs: Log[]
}

export function newWarehouse (name: string): Warehouse {
  return {
    name,
    liveLogs: []
  }
}

export function newEmptyWarehouse (): Warehouse {
  return newWarehouse('')
}

export function warehousePendingInitialization (warehouse: Warehouse): boolean {
  return warehouse.name === ''
}
