import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

import './Dashboard.css'
import Spinner from '../../../component/spinner/Spinner'
import { selectWarehouseState, stopMonitorWarehouse } from '../../../app/state/warehouse'
import Logs from '../../component/logs/Logs'
import { ArrowBack } from '@material-ui/icons'
import { NavLink, useParams } from 'react-router-dom'

const Dashboard: React.FC<any> = () => {
  const { loading } = useAppSelector(selectWarehouseState)
  const { warehouse } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(stopMonitorWarehouse(warehouse))
    }
  }, [dispatch])

  if (loading) {
    return (
      <div className={'dashboard loading shrinkable'}>
        <div className={'header'}>
          <NavLink to={'/dashboards'} className={'nav-back'}>
            <ArrowBack/>
          </NavLink>
          <div className={'selected-warehouse-name'}>{warehouse}</div>
        </div>
        <div className={'status connecting'}>status: connecting</div>
        <Spinner/>
      </div>
    )
  }

  return (
    <div className={'dashboard active shrinkable'}>
      <div className={'header'}>
        <NavLink to={'/dashboards'} className={'nav-back'}>
          <ArrowBack/>
        </NavLink>
        <div className={'selected-warehouse-name'}>{warehouse}</div>
      </div>
      <div className={'status connected'}>status: connected</div>
      <div className={'logs-metrics'}>
        <Logs/>
      </div>
    </div>
  )
}

export default Dashboard
