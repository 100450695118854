import { type Action, configureStore, type ThunkAction } from '@reduxjs/toolkit'
import warehouseListReducer from '../app/state/warehouseList'
import warehouseReducer from '../app/state/warehouse'
import { listenerMiddleware } from './middleware'
import listenWarehouseRetrieval from '../app/usecase/retrieveWarehouses'
import deps from '../adapter/dependencies'
import listenMonitorWarehouse from '../app/usecase/monitorWarehouse'
import listenStopMonitorWarehouse from '../app/usecase/stopMonitorWarehouse'

// use cases registration
listenWarehouseRetrieval()
listenMonitorWarehouse()
listenStopMonitorWarehouse()

export const store = configureStore({
  reducer: {
    warehouse: warehouseReducer,
    warehouseList: warehouseListReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: {
      extraArgument: {
        deps
      }
    }
  }).prepend(listenerMiddleware.middleware),
  devTools: true
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
