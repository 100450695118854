import { retrieveWarehouses, warehousesRetrieved } from '../state/warehouseList'
import { startListening } from '../../store/middleware'

const listenWarehouseRetrieval = (): any => startListening({
  actionCreator: retrieveWarehouses,
  effect: async (action, listenerApi) => {
    const deps = listenerApi.extra.deps

    try {
      const warehouses = await deps.ponfigue.retrieveWarehouses()
      listenerApi.dispatch(warehousesRetrieved(warehouses))
    } catch (e) {
      console.log(e)
    }
  }
})

export default listenWarehouseRetrieval
