import { startListening } from '../../store/middleware'
import { stopMonitorWarehouse } from '../state/warehouse'

const listenStopMonitorWarehouse = (): any => startListening({
  actionCreator: stopMonitorWarehouse,
  effect: async (action, listenerApi) => {
    const deps = listenerApi.extra.deps

    try {
      await deps.ponfigue.stopMonitorLogs(action.payload)
    } catch (e) {
      console.log(e)
    }
  }
})

export default listenStopMonitorWarehouse
