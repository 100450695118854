import React from 'react'

import './Dashboards.css'
import { selectWarehouseListState } from '../../../app/state/warehouseList'
import { useAppSelector } from '../../../store/hooks'
import Spinner from '../../../component/spinner/Spinner'
import { NavLink } from 'react-router-dom'
import { type Warehouse } from '../../../app/domain/warehouse'

const Dashboards: React.FC<any> = () => {
  const {
    warehouses,
    loading
  } = useAppSelector(selectWarehouseListState)

  if (loading) {
    return (
      <div className={'warehouse-list'}>
        <h1>Select warehouse</h1>
        <Spinner/>
      </div>
    )
  }

  return (
    <div className={'warehouse-list'}>
      <h1>Select warehouse</h1>
      {
        warehouses.map((wh: Warehouse) => (
          <NavLink to={`/dashboards/${wh.name}`} key={wh.name}>
            <div className={'selectable-warehouse container'}>
              {wh.name}
            </div>
          </NavLink>
        ))
      }
    </div>
  )
}

export default Dashboards
