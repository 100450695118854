import { createListenerMiddleware } from '@reduxjs/toolkit'
import deps from '../adapter/dependencies'

export const listenerMiddleware = createListenerMiddleware({
  extra: {
    deps
  }
})

export const {
  startListening,
  stopListening
} = listenerMiddleware
