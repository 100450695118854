import React from 'react'
import { useAppSelector } from '../../../store/hooks'

import './Logs.css'
import { selectLiveLogs } from '../../../app/state/warehouse'
import { type Log } from '../../../app/domain/log'

const Logs: React.FC<any> = () => {
  const liveLogs = useAppSelector(selectLiveLogs)

  function timeToString (log: Log): string {
    const date = new Date(log.time)

    return date.toLocaleDateString(
      'en-gb',
      {
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
    )
  }

  return (
    <div className={'logs'}>
      {
        liveLogs.slice(0).reverse().map((log: Log, i) => (
          <div key={log.id}>
            <div
              className={'log-container container log-received'}>
              <div className={'log-header'}>
                <div className={`log-level log-level-${log.level.toLowerCase()}`}>{log.level}</div>
                <div className={'timestamp'}>{timeToString(log)}</div>
              </div>
              <div className={'content'}>
                <div className={'logs-message'}>{log.message}</div>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Logs
