import { type PonfigueAPI } from '../../../../app/port/ponfigue'
import { type Warehouse } from '../../../../app/domain/warehouse'
import { interval, map, type Subscription } from 'rxjs'
import { type Log, LogLevel } from '../../../../app/domain/log'

export class InMemoryPonfigueAPI implements PonfigueAPI {
  warehouses: Warehouse[]
  engineLogSubscriptions = new Map<string, Subscription>()

  constructor (warehouses: Warehouse[] = []) {
    this.warehouses = warehouses
  }

  async retrieveWarehouses (): Promise<Warehouse[]> {
    console.log('retrieving warehouses')
    await new Promise((resolve) => setTimeout(resolve, 500))
    return this.warehouses
  }

  async findWarehouse (name: string): Promise<Warehouse> {
    console.log('retrieving warehouse')
    await new Promise((resolve) => setTimeout(resolve, 500))

    const warehouse = this.warehouses.find((warehouse) => warehouse.name === name)

    if (warehouse !== undefined) {
      return warehouse
    }

    throw new Error(`Warehouse ${name} not found`)
  }

  async monitorLogs (
    warehouseName: string,
    onLogReceived: (log: Log) => void
  ): Promise<void> {
    console.log(`monitoring logs of ${warehouseName}`)
    await new Promise((resolve) => setTimeout(resolve, 500))

    this.engineLogSubscriptions.set(warehouseName, fakeLogSub(onLogReceived))
  }

  async stopMonitorLogs (warehouseName: string): Promise<void> {
    console.log(`stop monitoring logs of ${warehouseName}`)
    const sub = this.engineLogSubscriptions.get(warehouseName)
    sub?.unsubscribe()

    this.engineLogSubscriptions.delete(warehouseName)
  }
}

const fakeLogSub = (onLogReceived: (log: Log) => void): Subscription => {
  const obs = interval(2000).pipe(
    map(() => {
      onLogReceived(fakeLog())
    })
  )

  return obs.subscribe()
}

const fakeLog = (): Log => {
  const level = Math.random() > 0.8 ? LogLevel.ERROR : LogLevel.INFO
  const message = level === LogLevel.ERROR ? randomStationLogMessageWithError() : randomStationLogMessage()

  return {
    id: generateRandId(30),
    level,
    message,
    time: new Date().getTime(),
    keyValues: {}
  }
}

const generateRandId = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomId = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    randomId += characters.charAt(randomIndex)
  }

  return randomId
}

const randomStationLogMessage = (additionalStatus: string[] = []): string => {
  const stationStatusList = [
    'Smooth operation',
    'On-time arrivals',
    'Available services',
    'Active escalators',
    'Efficient schedules',
    'Open for travel',
    'Enhanced security',
    'Reliable trains',
    'Clear announcements',
    'Updated timetables'
  ].concat(additionalStatus)

  const randomIndex = Math.floor(Math.random() * stationStatusList.length)

  return stationStatusList[randomIndex]
}

const randomStationLogMessageWithError = (): string => {
  const stationErrorMessages = [
    'Platform closure',
    'Train disruption',
    'Ticketing issue',
    'Escalator malfunction',
    'Track maintenance',
    'Station evacuation',
    'Security breach',
    'Train cancellation',
    'Announcement failure',
    'Timetable error'
  ]

  return randomStationLogMessage(stationErrorMessages)
}
