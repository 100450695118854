import { type PonfigueAPI } from '../../../../app/port/ponfigue'
import { type Warehouse } from '../../../../app/domain/warehouse'
import { type Log } from '../../../../app/domain/log'

export class HTTPPonfigueAPI implements PonfigueAPI {
  activeWebSockets = new Map<string, WebSocket>()

  websocketUrl: string
  warehouses: Warehouse[]

  constructor (websocketUrl: string, warehouses: Warehouse[]) {
    this.websocketUrl = websocketUrl
    this.warehouses = warehouses
  }

  async retrieveWarehouses (): Promise<Warehouse[]> {
    await new Promise((resolve) => setTimeout(resolve, 500))
    return this.warehouses
  }

  async findWarehouse (name: string): Promise<Warehouse> {
    await new Promise((resolve) => setTimeout(resolve, 500))

    const warehouse = this.warehouses.find((warehouse) => warehouse.name === name)
    if (warehouse !== undefined) {
      return warehouse
    }

    throw new Error('Warehouse not found')
  }

  async monitorLogs (
    warehouseName: string,
    onLogReceived: (log: Log) => void
  ): Promise<void> {
    try {
      const socket = new WebSocket(`${this.websocketUrl}?warehouse=${warehouseName}`)

      this.activeWebSockets.set(warehouseName, socket)

      socket.onmessage = (event) => {
        onLogReceived(JSON.parse(event.data))
      }
    } catch (e) {
      console.log(e)
    }
  }

  async stopMonitorLogs (warehouseName: string): Promise<void> {
    this.activeWebSockets.get(warehouseName)?.close()
    this.activeWebSockets.delete(warehouseName)
  }
}
