export interface Log {
  id: string
  time: number
  level: LogLevel
  message: string
  keyValues: any
}

export enum LogLevel {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}
