import { type Warehouse } from '../domain/warehouse'
import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from '../../store/store'

export interface WarehouseListState {
  warehouses: Warehouse[]
  loading: boolean
}

const initialState: WarehouseListState = {
  warehouses: [],
  loading: false
}

export const warehouseListSlice = createSlice({
  name: 'warehouseList',
  initialState,
  reducers: {
    retrieveWarehouses: (state) => {
      state.loading = true
    },
    warehousesRetrieved: (state, action) => {
      state.warehouses = action.payload
      state.loading = false
    }
  }
})

export const selectWarehouseListState = (state: RootState): WarehouseListState => state.warehouseList

export const { warehousesRetrieved, retrieveWarehouses } = warehouseListSlice.actions

export default warehouseListSlice.reducer
